import React, { useEffect } from 'react';
import { cn } from '@aph/ui/tailwind/cn';
import { Icon } from '../icon/icon';
import { ListItemRoot, ListItemThumbnail, ListItemTitle } from '../list-item/list-item';
import { Typography } from '../typography/typography';
import { BaseDropdown } from './base';
import type { DropdownProps } from './dropdown';

type DropdownMenuVariant = 'default' | 'outline';

interface DropdownMenuProps extends React.HTMLAttributes<HTMLElement>, DropdownProps {
  label?: string;
  variant?: DropdownMenuVariant;
  maxRows?: number;
}

/**
 *
 * @example
 * <DropdownMenu
 *  items="[...items]"
 *  selected={item}
 *  onSelected={(item) => setSelected(item)}
 * />
 * ```
 *
 */
export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  label,
  items,
  selected,
  variant,
  onSelected,
  maxRows,
}) => {
  const [current, setCurrent] = React.useState(selected);

  useEffect(() => {
    setCurrent(selected);
  }, [selected]);

  const isOutline = variant === 'outline';
  return (
    <BaseDropdown.Root modal={false}>
      <DropdownMenuLabel label={label} />

      <ListItemRoot
        className={cn(
          'hover:bg-elevated group h-[56px] w-full rounded-lg data-[state=open]:shadow-lg',
          {
            'border-default border': isOutline,
          },
        )}
        asChild
      >
        <BaseDropdown.Trigger>
          <ListItemThumbnail {...selected} />
          <div className="flex grow flex-col items-start">
            <ListItemTitle {...selected} />
            {selected?.subTitle && (
              <Typography typography="footnote" color="text-subtle">
                {selected.subTitle}
              </Typography>
            )}
          </div>
          <Icon name="ArrowheadDown" size="medium" className="group-data-[state=open]:rotate-180" />
        </BaseDropdown.Trigger>
      </ListItemRoot>

      <BaseDropdown.Content
        style={maxRows ? { maxHeight: maxRows * 48 } : undefined}
        className={cn({
          'border-default border': isOutline,
          'overflow-scroll': maxRows,
        })}
      >
        {items.map((item) => (
          <BaseDropdown.Item
            key={item.value}
            item={item}
            currentValue={current?.value}
            onClick={() => {
              setCurrent(item);
              onSelected?.(item);
            }}
          />
        ))}
      </BaseDropdown.Content>
    </BaseDropdown.Root>
  );
};

const DropdownMenuLabel = ({ label }: Pick<DropdownMenuProps, 'label'>) => {
  if (!label) {
    return null;
  }
  return (
    <Typography typography="subHeading" className="mb-0.5">
      {label}
    </Typography>
  );
};
