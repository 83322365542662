import React from 'react';
import {
  Content,
  type DropdownMenuContentProps,
  Item,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { cn } from '../../tailwind/cn';
import { ListItemRoot, ListItemThumbnail, ListItemTitle } from '../list-item/list-item';
import type { DropdownItem } from './dropdown';

type BaseContentProps = {
  children: React.ReactNode;
  additionalWidth?: number;
} & React.HTMLAttributes<HTMLElement> & DropdownMenuContentProps;

const BaseContent = ({
  children,
  additionalWidth = 0,
  className,
  style,
  ...rest
}: BaseContentProps) => (
  <Portal>
    <Content
      {...rest}
      className={cn(`bg-elevated z-50 my-1 overflow-hidden rounded-lg shadow-md`, className)}
      style={{ ...style, width: `calc(var(--radix-popper-anchor-width) + ${additionalWidth}px)` }}
    >
      {children}
    </Content>
  </Portal>
);

BaseContent.displayName = Content.displayName;

const BaseItem: React.FC<{
  item: DropdownItem;
  currentValue: DropdownItem['value'];
  onClick: (item: DropdownItem) => void;
}> = ({ item, currentValue, onClick }) => (
  <ListItemRoot
    aria-selected={item.value === currentValue}
    tabIndex={0}
    onClick={() => onClick(item)}
    asChild
  >
    <Item>
      <ListItemThumbnail {...item} />
      <ListItemTitle {...item} />
    </Item>
  </ListItemRoot>
);

BaseItem.displayName = Item.displayName;

export const BaseDropdown = {
  Root,
  Trigger,
  Content: BaseContent,
  Item: BaseItem,
};
